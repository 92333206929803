import { createModuleApi, OrderInfo, Page, PageResponse, User } from 'momai'

/**
 * 管理员API
 */
const adminApi = <T>() => createModuleApi<T>('admin')

/**
 * 获取未审批申请人数量
 */
const applicantsNum = () => adminApi<number>()({
  subUrl: 'applicants-num'
})

/**
 * 教师列表 + 管理员里列表
 */
const teacherListWithAdmin = () => adminApi<User[]>()({
  subUrl: 'teacher-with-super-admin-list'
})

/**
 * 分页获取所有的订单信息
 */
const pageAllOrder = (page: Page, keyword: string) => adminApi<PageResponse<OrderInfo>>()({
  subUrl: '/page-order-info',
  method: 'post',
  params: {
    ...page,
    keyword
  }
})

export {
  pageAllOrder,
  teacherListWithAdmin,
  applicantsNum
}
