import { api } from '../useAxios'
import { OrderInfo, Product } from 'momai'

const queryProduct = (size: number) => api.get('/product/query', { size })
const getProductInfo = <Product>(id: string | number) => api.get('/product/info/' + id)
const getAllProductInfo = <ProductVo>(id: string) => api.get('/product/allInfo/' + id)
const queryMyProduct = <Product>() => api.get('/product/queryMe')

const add = (product: Product) => api.post('/product/save', product)
const deleteProduct = (id: string) => api.post('/product/delete/' + id)
const queryCourse = (name: string) => api.get('/product/queryCourse', {
  name: name
})

const queryMyOrder = <OrderInfo>() => api.get('/orderinfo/queryMe')
const saveOrder = (orderInfo: OrderInfo) => api.post('/orderinfo/save', orderInfo)
const payOrder = (orderInfo: OrderInfo) => api.post('/orderinfo/pay', orderInfo)
const removeOrder = (id: number | string) => api.post('/orderinfo/delete/' + id)
const checkOrder = <Boolean>(id: number | string) => api.post('/orderinfo/checkOrder/' + id)
const url = encodeURIComponent(window.location.href)
const jsApiConfig = <Object>() => api.get('/pay/wx/jsApiConfig', { url })
const queryActivityByType = (params: any) => api.post('/product/queryNew', params)
export {
  add,
  queryProduct,
  queryCourse,
  getProductInfo,
  getAllProductInfo,
  saveOrder,
  payOrder,
  queryMyProduct,
  queryMyOrder,
  checkOrder,
  jsApiConfig,
  removeOrder,
  deleteProduct,
  queryActivityByType
}
