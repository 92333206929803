import { ref, computed } from 'vue'
import { CourseSeries } from 'momai'
import {all, queryCourseList} from '@/api/entity/course-series'

const courseSeriesList = ref<CourseSeries[]>([])

const courseList = ref<CourseSeries[]>([])

const fetchCourseSeries = () => {
  all().then(r => {
    courseSeriesList.value = r
  })
}

const selectCourseList = (params: any) => {
  queryCourseList(params).then((r: any) => {
    courseList.value = r.records
  })
}


type DetailCourseFun = (id: number) => CourseSeries | undefined

const getByCourseSeriesId = computed<DetailCourseFun>(() => (targetId: number) => courseSeriesList.value.find(({ id }) => id === targetId))

export {
  courseSeriesList,
  fetchCourseSeries,
  getByCourseSeriesId,
  courseList,
  selectCourseList
}
