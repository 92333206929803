import { getToken, setToken } from '@/utils/auth'
import { api } from '@/api/useAxios'
import fetchGlobalStates, { inited } from '@/states/init'
import { RouteLocationNormalizedLoaded, Router } from 'vue-router'
import { jsApiConfig } from '@/api/sales'
import qs from 'qs'
import wx from 'weixin-js-sdk'
import router from '@/router'

const is_weixin = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  const match = ua.match(/MicroMessenger/i);
  if (match === null) {
    return false;
  }
  if (match.includes('micromessenger')) {
    return true;
  }
  return false;
}

const {
  VUE_APP_ROUTE_NAMES_WITHOUT_TOKEN
} = process.env
const routesWithoutToken: any[] = VUE_APP_ROUTE_NAMES_WITHOUT_TOKEN ? VUE_APP_ROUTE_NAMES_WITHOUT_TOKEN.split(',') : []

interface WechatShare {
  title: string;
  desc: string;
  link: string;
  imgUrl: string;
}

const login = (router: Router, route: RouteLocationNormalizedLoaded) => {
  if (routesWithoutToken.includes(route.name) && !route.query.code && !route.query.state) {
    return true
  }
  const token = getToken()
  const url = window.location.href
  const { code } = qs.parse(window.location.search.replace(/\?/, ''))
  if (!token) {
    if(!is_weixin()){
      router.push('/login')
      return false
    }else{
      if (!code) {
        const {
          VUE_APP_WECHAT_APP_ID
        } = process.env
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${VUE_APP_WECHAT_APP_ID}&redirect_uri=${encodeURIComponent(url)}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
        return false
      }
      const storeUserInfo = ({ token, delFlag, userId, openid }: any) => {
        setToken({
          token,
          delFlag,
          id: userId,
          openid
        })
        fetchGlobalStates()
        // 删除code与state防止分享时出现携带code参数问题
        delete route.query.code
        delete route.query.state
        router.replace(route)
      }
      api.post('/login/wechat-h5', { code }).then(storeUserInfo)
      return false
    }
    
  }
  if (!inited.value) {
    fetchGlobalStates()
  }
  return true
}

const pagelogin = () => {
  const token = getToken()
  const url = window.location.href
  const { code } = qs.parse(window.location.search.replace(/\?/, ''))
  if (!token) {
    if (!code) {
      const {
        VUE_APP_WECHAT_APP_ID
      } = process.env
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${VUE_APP_WECHAT_APP_ID}&redirect_uri=${encodeURIComponent(url)}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
      return false
    }
    return true
  }
  return true
}

const changeShareInfo = (shareObj: WechatShare) => {
  jsApiConfig().then(configData => {
    wx.config({
      debug: false,
      ...configData,
      jsApiList: ['updateAppMessageShareData', 'updateAppMessageShare', 'updateTimelineShareData']
    })
    wx.ready(() => {
      wx.updateAppMessageShareData(shareObj)
      wx.updateTimelineShareData(shareObj)
    })
  })
}

export {
  changeShareInfo,
  login,
  pagelogin
}
