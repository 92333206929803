export default [
  {
    path: 'mine/other/:userId',
    name: 'otherHomePage',
    component: () => import('@/views/mine/other/Index.vue'),
    meta: {
      title: '他人主页',
      showBottom: false,
      showNav: false
    }
  },
  {
    path: 'mine/student',
    name: 'studentHomePage',
    component: () => import('@/views/mine/personal/Index.vue'),
    meta: {
      title: '学员主页',
      showBottom: false,
      showNav: false
    }
  }
]
