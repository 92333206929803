import { App, defineAsyncComponent } from 'vue'
import moment from 'moment'
import { Toast } from 'vant'

export default (app: App) => {
  app.component('MomaiSectionTitle', defineAsyncComponent(() => import('@/components/MomaiSectionTitle.vue')))
  app.component('MomaiSectionTitleNew', defineAsyncComponent(() => import('@/components/MomaiSectionTitleNew.vue')))
  app.component('MomaiCourseSeriesList', defineAsyncComponent(() => import('@/components/MomaiCourseSeriesList.vue')))
  app.component('MomaiCourseList', defineAsyncComponent(() => import('@/components/MomaiCourseList.vue')))
  app.component('MomaiCourseListNew', defineAsyncComponent(() => import('@/components/MomaiCourseListNew.vue')))
  app.component('MomaiComments', defineAsyncComponent(() => import('@/components/MomaiComments.vue')))
  app.component('MomaiBottomComment', defineAsyncComponent(() => import('@/components/MomaiBottomComment.vue')))
  app.component('MomaiVideoPlayer', defineAsyncComponent(() => import('@/components/media/MomaiVideoPlayer.vue')))
  app.component('MomaiVideoPlayerNew', defineAsyncComponent(() => import('@/components/media/MomaiVideoPlayerNew.vue')))

  app.component('MomaiRecorder', defineAsyncComponent(() => import('@/components/media/MomaiRecorder/Index.vue')))
  app.component('MomaiClassesPicker', defineAsyncComponent(() => import('@/components/MomaiClassesPicker.vue')))
  app.component('MomaiTopSearch', defineAsyncComponent(() => import('@/components/MomaiTopSearch.vue')))
  app.component('MomaiMenu', defineAsyncComponent(() => import('@/components/MomaiMenu/Index.vue')))
  app.component('MomaiDraggableBtn', defineAsyncComponent(() => import('@/components/MomaiDraggableBtn.vue')))
  app.component('MomaiTagSelector', defineAsyncComponent(() => import('@/components/MomaiTagSelector.vue')))
  app.component('MomaiCoverUploader', defineAsyncComponent(() => import('@/components/MomaiCoverUploader.vue')))
  app.component('MomaiCoverUploaderSpecial', defineAsyncComponent(() => import('@/components/MomaiCoverUploaderSpecial.vue')))
  app.component('MomaiProductList', defineAsyncComponent(() => import('@/components/MomaiProductList.vue')))
  app.component('MomaiPhoneCode', defineAsyncComponent(() => import('@/components/MomaiPhoneCode.vue')))
  app.component('MomaiCourseListWithChildren', defineAsyncComponent(() => import('@/components/MomaiCourseListWithChildren.vue')))
  app.component('MomaiFormItem', defineAsyncComponent(() => import('@/components/form/MomaiFormItem.vue')))
  app.component('MomaiCourseSelector', defineAsyncComponent(() => import('@/components/MomaiCourseSelector.vue')))
  app.component('MomaiSaveBtn', defineAsyncComponent(() => import('@/components/basic/BottomSaveBtn.vue')))

  // 新加
  app.component('MomaiSwipe', defineAsyncComponent(() => import('@/components/Momai/MomaiSwipe.vue')))
  app.component('MomaiCommentPublish', defineAsyncComponent(() => import('@/components/Momai/MomaiCommentPublish/Index.vue')))
  app.component('MomaiSelectTitle', defineAsyncComponent(() => import('@/components/Momai/MomaiSelectTitle.vue')))
  app.component('MomaiCalendar', defineAsyncComponent(() => import('@/components/Momai/MomaiCalendar.vue')))
  app.component('MomaiAudioplay', defineAsyncComponent(() => import('@/components/Momai/MomaiAudioplay.vue')))
  app.component('MomaiImg', defineAsyncComponent(() => import('@/components/Momai/MomaiImg.vue')))
  app.component('MomaiComment', defineAsyncComponent(() => import('@/components/Momai/MomaiComment/Comment.vue')))
  app.component('MomaiCommentTop', defineAsyncComponent(() => import('@/components/Momai/MomaiComment/CommentTop.vue')))
  app.component('MomaiCommentDetail', defineAsyncComponent(() => import('@/components/Momai/MomaiComment/Detail.vue')))
  app.component('svg-icon', defineAsyncComponent(() => import('@/components/Momai/SvgIcon/index.vue')))
  app.component('MomaiCommentList', defineAsyncComponent(() => import('@/components/Momai/MomaiComment/CommentList.vue')))
  app.component('MomaiCourseTitle', defineAsyncComponent(() => import('@/components/Momai/MomaiCourseTitle.vue')))

  app.component('CompanyChoose', defineAsyncComponent(() => import('@/components/comp/CompanyChoose.vue')))
  app.component('MomaiRecorderNew', defineAsyncComponent(() => import('@/components/media/MomaiRecorder/RecorderNew.vue')))
  app.component('MomaiBottom', defineAsyncComponent(() => import('@/components/MomaiBottom.vue')))

  moment.locale('zh-cn')
  Toast.setDefaultOptions({ forbidClick: true })
}
