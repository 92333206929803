import { reactive, ref } from 'vue'
import router from '@/router'

const layoutState = reactive({
  showNav: true,
  title: '标题',
  showBottom: true,
  isGoback: false
})

const homeworkActiveTab = ref(0)

const goBack = () => {
  layoutState.isGoback = true
  router.go(-1)
}

export {
  homeworkActiveTab,
  layoutState,
  goBack
}
