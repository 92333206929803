interface Study {
  // CommentTopProps: {
    userAvatar: string;
    hasFollowing: boolean;
    userId: number;
    type: string;
    isGood: boolean;
    name: string;
    position: string;
  // };
  // CommentDetailProps: {
    imgs: Array<string>;
    audioUrl: string;
    videoUrl: string;
    poster: string;
    text: string;
    courseserialName: string;
    courseserialId: number;
  // };
  // CommentProps: {
    ordId: number;
    ordType: number; // 类型，1：作业，2：评论,3：活动,4:打卡
    createTime: string; // 创建时间
    dzCnt: number; // 点赞数
    pjCnt: number; // 评价数
    scCnt: number; // 收藏数
    fxCnt: number; // 分享数
    hasDz: boolean; // 我是否点赞
    hasSc: boolean; // 我是否收藏
    teaEva: string; // 导师评价
    teaPic: string; // 导师评价
    teaVoice: string; // 导师评价
    isQuality: boolean; // 是否优质
    // userId: number;
  // };
    teacherName?: string;
    teacherAvatar?: string;
    teacherId?: number;
}

const createStudyProps = (): Study => ({
  userAvatar: '',
  hasFollowing: false,
  userId: -1,
  type: '',
  isGood: false,
  name: '',
  position: '',
  imgs: [],
  audioUrl: '',
  videoUrl: '',
  poster: '',
  text: '',
  courseserialName: '',
  courseserialId: -1,
  ordId: -1,
  ordType: -1,
  createTime: '',
  dzCnt: 0,
  pjCnt: 0,
  scCnt: 0,
  fxCnt: 0,
  hasDz: false,
  hasSc: false,
  teaEva: '',
  teaPic: '',
  teaVoice: '',
  isQuality: false
})
export {
  Study,
  createStudyProps
}
