import { ref, computed } from 'vue'
import { Tag } from 'momai'
import { api } from '@/api/useAxios'

const allTags = ref<Tag[]>([])

const fetchTags = () => api.get('/tag/listAll').then(r => {
  allTags.value = r
})

// 导师点评用的标签
const auditTags = computed(() => allTags.value.filter(t => t.tagType === '1'))

// 课程系列标签
const courseSeriesTags = computed(() => allTags.value.filter(t => t.tagType === '0'))

// 课程系列标签
const courseTypeTags = computed(() => allTags.value.filter(t => t.tagType === '2'))

const teacherType = computed(() => allTags.value.filter(t => t.tagType === '3'))
export {
  allTags,
  auditTags,
  courseSeriesTags,
  courseTypeTags,
  fetchTags,
  teacherType
}
