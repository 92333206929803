import { Page, Class, createModuleApi, PageResponse } from 'momai'

const classApi = <T>() => createModuleApi<T>('classinfo')

const page = ({
  keyword,
  pageData
}: {
  pageData: Page;
  keyword: string;
  }) => classApi<PageResponse<Class>>()({
    subUrl: 'page',
    method: 'post',
    params: {
      ...pageData,
      keyword
    }
  })

const all = () => classApi<Class[]>()({ subUrl: 'listAll' })

const detail = (id: number) => classApi<Class>()({
  subUrl: `info/${id}`
})

const save = ({
  id,
  courseId,
  className,
  sort,
  userId
}: Class) => classApi<string>()({
  subUrl: 'save',
  method: 'post',
  params: {
    userId,
    id,
    courseId,
    className,
    sort
  }
})

export {
  page,
  save,
  detail,
  all
}
