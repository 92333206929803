import { fetchAllParams } from '@/api/universal/system'
import { SystemParam } from 'momai'
import { ref } from 'vue'

const systemParams = ref<SystemParam[]>([])

const fetchSystemParams = () => {
  fetchAllParams().then(r => {
    systemParams.value = r
  })
}

export {
  fetchSystemParams,
  systemParams
}
