import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'admin/review-comments',
    component: () => import('@/views/admin/review-comments/Index.vue'),
    meta: {
      title: '评论审核',
      showBottom: false
    }
  },
  {
    path: 'admin/user-list',
    component: () => import('@/views/admin/user-list/Index.vue'),
    meta: {
      title: '人员管理',
      showBottom: false
    }
  },
  {
    path: 'admin/tag-list',
    component: () => import('@/views/admin/tag-list/Index.vue'),
    meta: {
      title: '标签管理',
      showBottom: false
    }
  },
  {
    path: 'admin/order-list',
    component: () => import('@/views/admin/order-list/Index.vue'),
    meta: {
      title: '订单管理',
      showBottom: false
    }
  }
]

export default routes
