export default [
  {
    path: 'user',
    component: () => import('@/views/user/Index.vue'),
    meta: {
      title: '个人中心',
      showNav: false
    }
  },
  {
    path: 'user-collects',
    component: () => import('@/views/user/collects/Index.vue'),
    meta: {
      title: '我的收藏',
      showBottom: false
    }
  },
  {
    path: 'user/received-comments',
    component: () => import('@/views/user/received-comments/Index.vue'),
    meta: {
      title: '我收到的评论',
      showBottom: false
    }
  },
  {
    path: 'user-courses-learned',
    component: () => import('@/views/user/courses-learned/Index.vue'),
    meta: {
      title: '我的课程',
      showBottom: false
    }
  },
  {
    path: 'user/todo-homeworks',
    component: () => import('@/views/user/todo-list/Index.vue'),
    meta: {
      title: '我的作业',
      showBottom: false
    }
  },
  {
    path: 'user/system-settings',
    component: () => import('@/views/user/system-settings/Index.vue'),
    meta: {
      title: '系统设置',
      showBottom: false
    }
  },
  {
    path: 'user/system-settings/change-phone',
    component: () => import('@/views/user/system-settings/change-phone/Index.vue'),
    meta: {
      title: '手机号绑定',
      showBottom: false
    }
  },
  {
    path: 'user/orders',
    component: () => import('@/views/user/sales/Orders.vue'),
    meta: {
      title: '我的订单',
      showBottom: false
    }
  },
  {
    path: 'user/productDetail/:productId',
    component: () => import('@/views/user/sales/ProductDetail.vue'),
    meta: {
      title: '已购活动产品',
      showBottom: false
    }
  },
  {
    path: 'user/score-detail',
    component: () => import('@/views/user/score-detail/Index.vue'),
    meta: {
      title: '魔豆明细',
      showBottom: false
    }
  },
  {
    path: 'user/userfollow/:userId',
    name: 'userfollow',
    component: () => import('@/views/user/user-fansfollow/Follow.vue'),
    meta: {
      title: '关注列表',
      showBottom: false
    }
  },
  {
    path: 'user/userfans/:userId',
    name: 'userfans',
    component: () => import('@/views/user/user-fansfollow/Fans.vue'),
    meta: {
      title: '粉丝列表',
      showBottom: false
    }
  }
]
