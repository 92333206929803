interface Daily {
  id: number | string;
  dailyTime: string;
  title: string;
  detailDesc: string;
  detailImg: string;
  video: string;
  videoCover: string;
  videoLength: number;
  voice: string;
  voiceLength: number;
  joinNum: string;
  joinLimit: number;
  finshReward: number;
  dailyId: number;
}

const createDaily = (): Daily => ({
  id: '',
  dailyTime: '',
  title: '',
  detailDesc: '',
  detailImg: '',
  video: '',
  videoCover: '',
  videoLength: 0,
  voice: '',
  voiceLength: 0,
  joinNum: '',
  joinLimit: 1,
  finshReward: 0,
  dailyId: 0
})

interface DailyHomeWork {
  id: number | string;
  name: string;
  userAvatar: string;
  userId: string;
  detailDesc: string;
  detailImg: Array<string>;
  video: string;
  videoCover: string;
  voice: string;
  createTime: string;
  position: string;
  dzCnt: number;
  hasDz: boolean;
  pjCnt: number;
  scCnt: number;
  hasSc: boolean;
  fxCnt: number;
  dailyId: number;
  ordType: number;
}

const createDailyHomeWork = (): DailyHomeWork => ({
  id: '',
  name: '',
  userAvatar: '',
  userId: '',
  detailDesc: '',
  detailImg: [],
  video: '',
  videoCover: '',
  voice: '',
  createTime: '',
  position: '',
  dzCnt: 0,
  hasDz: false,
  pjCnt: 0,
  scCnt: 0,
  hasSc: false,
  fxCnt: 0,
  dailyId: 0,
  ordType: 4
})

export {
  Daily,
  createDaily,
  DailyHomeWork,
  createDailyHomeWork
}
