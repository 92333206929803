export default [
  {
    path: 'search',
    component: () => import('@/views/search/Index.vue'),
    meta: {
      title: '我要打卡',
      showBottom: false
    }
  },
  {
    path: 'course-series-list',
    name: 'courseSeriesList',
    component: () => import('@/views/course-series/course-list/Index.vue'),
    meta: {
      title: '课程系列-课程列表',
      showBottom: false,
      showNav: false
    }
  },
  {
    path: 'coursedetail/:courseSeriesId',
    name: 'courseNew',
    component: () => import('@/views/course-series/course-new/Index.vue'),
    meta: {
      title: '课程详情',
      showBottom: false,
      showNav: false
    }
  },
  {
    path: 'course-series/course-detail/:courseId',
    name: 'courseDetail',
    component: () => import('@/views/course-series/course-detail/Index.vue'),
    meta: {
      title: '课程内容',
      showBottom: false
    }
  },
  {
    path: 'course-series/course/homework/:courseId',
    name: 'homeworkIndex',
    component: () => import('@/views/homework/Index.vue'),
    meta: {
      title: '作业内容',
      showBottom: false
    }
  }
]
