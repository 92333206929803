import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'admin/user-list/applicants',
    component: () => import('@/views/admin/user-list/UserList.vue'),
    meta: {
      title: '申请人列表',
      showBottom: false
    },
    props: {
      delFlag: 0,
      toFormatter: id => `/admin/user-list/applicants/detail/${id}`
    }
  },
  {
    path: 'admin/user-list/applicants/detail/:userId',
    component: () => import('@/views/admin/user-list/applicants/detail/Index.vue'),
    meta: {
      title: '申请人详情',
      showBottom: false
    }
  }
]

export default routes
