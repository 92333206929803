import { fetchCourseSeries } from '@/states/couseSeries'
import { fetchLoginUserInfo, fetchApplicants } from './login'
import { fetchAllClasses } from './classes'
import { fetchCheckedClasses } from './checkedClasses'
import { fetchTags } from './tags'
import { ref } from 'vue'
import { fetchSystemParams } from './system'
import { getDelFlag } from '@/utils/auth'

const inited = ref(false)

export default () => {
  fetchCourseSeries()
  fetchLoginUserInfo()
  fetchAllClasses()
  fetchCheckedClasses()
  fetchTags()
  fetchSystemParams()
  if (getDelFlag() === '9') {
    fetchApplicants()
  }
  inited.value = true
}

export {
  inited
}
