import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'admin/class-list',
    component: () => import('@/views/admin/class-list/Index.vue'),
    meta: {
      title: '班级管理',
      showBottom: false
    }
  },
  {
    path: 'admin/class/detail/:classId',
    component: () => import('@/views/admin/class-list/detail/Index.vue'),
    meta: {
      title: '班级详情',
      showBottom: false
    }
  },
  {
    path: 'admin/class/add',
    component: () => import('@/views/admin/class-list/add/Index.vue'),
    meta: {
      title: '新建班级',
      showBottom: false
    }
  }
]

export default routes
