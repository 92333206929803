import { CourseSeries, createModuleApi, CourseSeriesDetail } from 'momai'

const formatCash = (courseSeries: CourseSeries) => {
  courseSeries.cashPrice = String((Number(courseSeries.cashPrice) * 100))
  courseSeries.salePrice = String((Number(courseSeries.salePrice) * 100))
}

const courseApi = <T>() => createModuleApi<T>('course')

const all = () => courseApi<CourseSeries[]>()({
  subUrl: 'qryall'
})
const add = (courseSeries: CourseSeries) => {
  formatCash(courseSeries)
  return courseApi<string>()({
    subUrl: 'save',
    method: 'post',
    params: courseSeries
  })
}

const detail = (id: number) => courseApi<CourseSeries>()({
  subUrl: `info/${id}`
})

const detailall = (id: number) => courseApi<CourseSeriesDetail>()({
  subUrl: `info/detail/${id}`
})

const del = (id: number) => courseApi<string>()({
  subUrl: 'delete',
  params: [id],
  method: 'post'
})

const update = (courseSeries: CourseSeries) => {
  formatCash(courseSeries)
  return courseApi<string>()({
    subUrl: 'update',
    params: courseSeries,
    method: 'post'
  })
}

const queryCourseList = (params: any) => courseApi<CourseSeries[]>()({
  subUrl: 'qryallNew',
  method: 'post',
  params: params
})

export {
  add,
  all,
  detail,
  detailall,
  del,
  update,
  queryCourseList
}
