import { ActiveColor } from './color'

/**
 * 课程作业
 */
interface Homework {
  id: number;
  companyId: number;
  courseId: number;
  userId: number;
  /**
   * 0 待审核; 1 审核通过; 2 审核失败;
   */
  status: '0' | '1' | '2';
  video: string;
  videoCover: string;
  videoLength: number;
  voice: string,
  /**
   * 0 公开; 1 私密;
   */
  self: '0' | '1';
  workName: string;
  /**
   * 导师文字评价
   */
  teaEva: string;
  /**
   * 导师语音评价
   */
  teaVoice: string;
  /**
   * 导师图片评价
   */
  teaPic: string;
  /**
   * 0 未读; 1 已读;
   */
  unread: '0' | '1';
  createTime: string;
  /**
   * 课程系列名称
   */
  courseName?: string;
  userAvatar?: string;
  userName?: string;
  hasFollowing?: boolean;
  /**
   * 课程名称
   */
  courseInfoName?: string;
  workDzColor?: ActiveColor;
  workDzCnt?: number;
  text: string;
  img: string;
}

/**
 * 创建空作业
 */
const createHomework = ({
  userId = 0,
  courseId = 0
}: {
  userId?: number;
  courseId?: number;
}): Homework => ({
  id: 0,
  companyId: 999,
  video: '',
  videoCover: '',
  videoLength: 0,
  voice: '',
  courseId,
  userId,
  status: '0',
  teaEva: '',
  teaVoice: '',
  teaPic: '',
  self: '0',
  unread: '0',
  workName: '',
  createTime: '',
  text: '',
  img: ''
})

export {
  Homework,
  createHomework
}
