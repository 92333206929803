import { DelFlag, Page, createModuleApi, User, PageResponse } from 'momai'

interface Param {
  username?: string;
  delFlag: DelFlag | undefined;
  pageData: Page;
}

const userApi = <T>() => createModuleApi<T>('sysuser')
const focusApi = <T>() => createModuleApi<T>('focus')

const update = (userInfo: {
  id: number;
  [field: string]: any;
}) => userApi<string>()({
  subUrl: 'update',
  params: userInfo,
  method: 'post'
})

const detail = (id: string | string[] | null) => userApi<User>()({
  subUrl: `info/${id}`
})

const list = ({
  delFlag,
  username = '',
  pageData
}: Param) => userApi<PageResponse<User>>()({
  subUrl: 'page',
  method: 'post',
  params: {
    ...pageData,
    delFlag,
    username
  }
})

const listByType = (dealFlag: DelFlag) => userApi<User[]>()({
  subUrl: 'listByType',
  params: { dealFlag },
  method: 'post'
})

const delUserById = (id: number) => userApi<string>()({
  subUrl: 'delUserById',
  method: 'post',
  params: { userId: id }
})

const getUserdynamic = (params: any) => userApi()({
  subUrl: 'getUserStuPageWorksNew',
  method: 'post',
  params: params
})

const getUserInfo = (id: any) => userApi()({
  subUrl: `userInfo/${id}`
})

/**
 * 分页查询我关注
 * @param params
 */
 const pageMyFollow = ({
    userId,
    pageData
  }: {
    pageData: Page;
    userId: string;
  }) => focusApi<PageResponse<User>>()({
  subUrl: 'findfollowings',
  method: 'post',
  params: {
    ...pageData,
    userId
  }
})

/**
 * 分页查询我关注
 * @param params
 */
 const pageMyFans = ({
  userId,
  pageData
}: {
  pageData: Page;
  userId: string;
}) => focusApi<PageResponse<User>>()({
subUrl: 'findfans',
method: 'post',
params: {
  ...pageData,
  userId
}
})
export {
  listByType,
  update,
  detail,
  list,
  delUserById,
  getUserdynamic,
  getUserInfo,
  pageMyFollow,
  pageMyFans
}
