import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'teacher/detail/:userId',
    component: () => import('@/views/teacher/Detail.vue'),
    meta: {
      title: '导师详情',
      showBottom: false,
      showNav: false
    }
  },
  {
    path: 'teacher/homework/pending',
    component: () => import('@/views/teacher/HomeworkList.vue'),
    props: {
      type: 'pending',
      emptyText: '该课程下无待批作业'
    },
    meta: {
      title: '待批作业',
      showBottom: false
    }
  },
  {
    path: 'teacher/homework/audit/:homeworkId',
    component: () => import('@/views/teacher/audit/Index.vue'),
    meta: {
      title: '导师点评',
      showBottom: false
    }
  },
  {
    path: 'teacher/homework/approved',
    component: () => import('@/views/teacher/HomeworkList.vue'),
    meta: {
      title: '已批作业',
      showBottom: false
    }
  }
]

export default routes
