import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'admin/course-series-list',
    component: () => import('@/views/admin/course-series/list/Index.vue'),
    meta: {
      title: '课程系列管理',
      showBottom: false
    }
  }, {
    path: 'admin/course-series/detail/:courseSeriesId',
    component: () => import('@/views/admin/course-series/detail/Index.vue'),
    meta: {
      title: '课程系列详情',
      showBottom: false
    }
  }, {
    path: 'admin/course-series/add',
    component: () => import('@/views/admin/course-series/add/Index.vue'),
    meta: {
      title: '添加课程系列',
      showBottom: false
    }
  }, {
    path: 'admin/course-series/course/detail/:courseId',
    component: () => import('@/views/admin/course-series/detail/course/detail/Index.vue'),
    meta: {
      title: '课程详情',
      showBottom: false
    }
  }, {
    path: 'admin/course-series/course/add/:courseSeriesId',
    component: () => import('@/views/admin/course-series/detail/course/add/Index.vue'),
    meta: {
      title: '添加课程',
      showBottom: false
    }
  }
]

export default routes
