/**
 * 0 路人; 1 学员; 2 导师; 3 管理员; 9 超级管理员;
 */
type DelFlag = 0 | 1 | 2 | 3 | 9 | '0' | '1' | '2' | '3' | '9'
/**
   * 用户
   */
interface User {
  id: number;
  wechatH5Openid?: string;
  wechatOpenid?: string;
  username: string;
  avatar?: string;
  real_usernme?: string;
  sex?: number;
  mobile?: string;
  userClass?: string;
  business?: string;
  position?: string;
  working_year?: string;
  birthday?: string;
  hometowm?: string;
  interests?: string;
  resources?: string;
  hopes?: string;
  createTime?: string;
  modifyTime?: string;
  delFlag?: DelFlag;
  score: number;
  rsrv1?: string;
}

const createUser = (): User => ({
  id: 0,
  username: '',
  score: 0
})

interface MmTeacher {
  id: number;
  userId?: string;
  teacherName?: string;
  teacherIdentity: string;
  teacherDesc: string;
  teacherAvatar?: string;
  teacherImg?: string;
  teacherInfo?: string;
  video?: string;
  videoCover?: string;
  videoLength?: number;
  contentTitle1?: string;
  contentDesc1?: string;
  contentTitle2?: string;
  contentDesc2?: string;
  contentTitle3?: string;
  contentDesc3?: string;
  teacherType?: string;
  createTime?: string;
  modifyTime?: string;
  sort?: string;
  rsrv1?: string;
  rsrv2?: string;
  rsrv3?: string;
  rsrv4?: string;
}

const createMmTeacher = (): MmTeacher => ({
  id: 0,
  userId: '',
  teacherName: '',
  teacherIdentity: '',
  teacherDesc: '',
  teacherAvatar: '',
  teacherImg: '',
  teacherInfo: '',
  video: '',
  videoCover: '',
  videoLength: 0,
  contentTitle1: '',
  contentDesc1: '',
  contentTitle2: '',
  contentDesc2: '',
  contentTitle3: '',
  contentDesc3: '',
  teacherType: '',
  sort: '',
  rsrv1: ''
})

export {
  DelFlag,
  User,
  createUser,
  MmTeacher,
  createMmTeacher
}
