interface Class {
  id: number | string;
  className: string;
  createTime: string;
  courseId: number | string;
  sort: number | string;
  userId: string;
}

const createClass = (): Class => ({
  id: '',
  className: '',
  createTime: '',
  courseId: '',
  sort: '',
  userId: ''
})

export {
  createClass,
  Class
}
