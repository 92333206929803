import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'admin/user-list/teachers',
    component: () => import('@/views/admin/user-list/UserList.vue'),
    meta: {
      title: '导师列表',
      showBottom: false
    },
    props: {
      delFlag: 2,
      toFormatter: id => `/admin/user-list/user/detail/${id}`
    }
  }, {
    path: 'admin/user-list/user/detail/:userId',
    component: () => import('@/views/admin/user-list/detail/Index.vue'),
    meta: {
      title: '导师详情',
      showBottom: false
    }
  }, {
    path: 'admin/user-list/teacheredit/:userId',
    component: () => import('@/views/admin/user-list/detail/TeacherEdit.vue'),
    meta: {
      title: '导师信息编辑',
      showBottom: false
    }
  }
]

export default routes
