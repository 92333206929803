import { Review } from './review'
import { ActiveColor } from './color'
/**
 * 课程系列下的课程
 */
interface Course {
  id: number | '';
  courseId: number | '';
  name: string;
  desc: string;
  img: string;
  detailDesc: string;
  detailImg: string;
  exampleVideo: string;
  exampleVideoCover: string;
  exampleVideoLength: string;
  video: string;
  videoCover: string;
  videoLength: string;
  workName?: string;
  workRequire?: string;
  dealTag: string;
  rsrv1: string;
  rsrv2: string;
  rsrv3: string;
  rsrv4: string;
}

/**
 * 课程系列
 */
interface CourseSeries {
  id: number | string;
  companyId: number;
  courseDesc: string;
  createTime: string;
  img: string;
  sort: number;
  courseAmount: string;
  tag: string;
  courseName: string;
  courseType: string;
  /**
   * 课程类型：0 线上；1 线下；默认线下
   */
  type: 0 | 1 | '0' | '1';
  /**
   * 课程魔豆价格（用于站内魔豆购买）
   */
  magicBeanPrice: number;
  /**
   * 课程现金价格（用于微信支付）
   */
  cashPrice: string;
  salePrice: string;
  /**
   * 课程系列图文介绍图片
   */
  graphicIntroduction: string;

  /**
   * 推广奖励
   */
  recommendReward: number;
  shareReward: number;
  teaId: string;
  dealTag: string;
  rsrv1: string;
}

/**
 * 课程详情
 */
interface CourseDetail extends Course {
  courseInfoDzCnt: number;
  courseInfoDzColor: ActiveColor;
  courseInfoPjCnt: number;
  courseInfoPjColor: ActiveColor;
  courseInfoScCnt: number;
  courseInfoScColor: ActiveColor;
  isExistsPjFlag: boolean;
  evaulIPage: {
    records: Review[];
    total: number;
    current: number;
    size: number;
  };
  [field: string]: any;
}

/**
 * 课程系列详情
 */
 interface CourseSeriesDetail extends CourseSeries {
  teacherName: string;
  teacherIdentity: string;
  courseInfoDzCnt: number;
  courseInfoDzColor: string;
  courseInfoPjCnt: number;
  courseInfoPjColor: string;
  courseInfoScCnt: number;
  courseInfoScColor: string;
  isExistsPjFlag: boolean;
  evaulIPage: {
    records: Review[];
    total: number;
    current: number;
    size: number;
  };
  [field: string]: any;
}

interface RecommendCourse {
  courseId: number | string;
  courseName: string;
  img: string;
  studyNum: number
}

const createRecommendCourse = (): RecommendCourse => ({
  courseId: '',
  courseName: '',
  img: '',
  studyNum: 0
})

const createCourseSeries = (): CourseSeries => ({
  id: '',
  companyId: 999,
  courseType: '',
  courseDesc: '',
  createTime: '',
  img: '',
  sort: 1,
  courseAmount: '',
  tag: '',
  type: 1,
  courseName: '',
  magicBeanPrice: 1,
  cashPrice: '',
  salePrice: '',
  graphicIntroduction: '',
  recommendReward: 0,
  shareReward: 0,
  teaId: '',
  dealTag: '',
  rsrv1: ''
})

const createCourse = (courseId: number | '' = ''): Course => ({
  id: '',
  courseId,
  name: '',
  desc: '',
  img: '',
  video: '',
  videoCover: '',
  videoLength: '',
  detailDesc: '',
  detailImg: '',
  exampleVideo: '',
  exampleVideoCover: '',
  exampleVideoLength: '',
  workRequire: '',
  workName: '',
  dealTag: '',
  rsrv1: '',
  rsrv2: '',
  rsrv3: '',
  rsrv4: ''
})

const createCourseDetail = (): CourseDetail => ({
  ...createCourse(),
  courseInfoDzCnt: 3,
  courseInfoPjCnt: 20,
  courseInfoScCnt: 2,
  courseInfoDzColor: 'orange',
  courseInfoPjColor: '#8a8a8a',
  courseInfoScColor: '#8a8a8a',
  isExistsPjFlag: true,
  evaulIPage: {
    records: [],
    total: 0,
    size: 10,
    current: 1
  }
})

const createCourseSeriesDetail = (): CourseSeriesDetail => ({
  ...createCourseSeries(),
  teacherName: '',
  teacherIdentity: '',
  courseInfoDzCnt: 3,
  courseInfoPjCnt: 20,
  courseInfoScCnt: 2,
  courseInfoDzColor: 'orange',
  courseInfoPjColor: '#8a8a8a',
  courseInfoScColor: '#8a8a8a',
  isExistsPjFlag: true,
  evaulIPage: {
    records: [],
    total: 0,
    size: 10,
    current: 1
  }
})

interface UserCourseNote {
  userId: number;
  courseId: number;
  createTime: string;
  courseNote: string;
}

const createUserCourseNote = ({
  userId,
  courseId
}: {
  userId: number;
  courseId: number;
}): UserCourseNote => ({
  userId,
  courseId,
  createTime: '',
  courseNote: ''
})

export {
  UserCourseNote,
  createUserCourseNote,
  createCourse,
  createCourseSeries,
  createCourseDetail,
  createCourseSeriesDetail,
  Course,
  CourseSeries,
  CourseDetail,
  CourseSeriesDetail,
  RecommendCourse,
  createRecommendCourse
}
