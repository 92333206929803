import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { layoutState } from '@/states/layout'
import { login } from '@/utils/wechat'
import { logapi } from '@/api/useLogAxios';

// modules文件夹下的ts文件导出的路由会被自动组装到这里
const rqRoutes = require.context('./modules', true, /\.ts$/)

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/layouts/Main.vue'),
    redirect:'/study',
    children: [
      {
        path: '/study',
        name: 'mIndex',
        component: () => import('@/views/study/Index.vue'),
        meta: {
          title: '学习圈',
          showNav: false
        }
      },
      {
        path: '/home',
        component: () => import('@/views/home/Index.vue'),
        meta: {
          title: '首页',
          showNav: false
        }
      },
      {
        path: '/findtea',
        name: 'findtea',
        component: () => import('@/views/teacher/List.vue'),
        meta: {
          title: '导师列表',
          showNav: false
        }
      },
      {
        path: 'description',
        name: 'description',
        component: () => import('@/views/home/Description.vue'),
        meta: {
          title: '魔脉介绍',
          showBottom: false
        }
      },
      ...rqRoutes.keys().reduce((arr: RouteRecordRaw[], path) => [...arr, ...rqRoutes(path).default], [])
    ]
  },
  {
    path: '/bind-phone',
    name: 'bindPhone',
    component: () => import('@/views/bind-phone/Index.vue'),
    meta: {
      title: '绑定手机号'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Index.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('@/views/bind-phone/Agreement.vue'),
    meta: {
      title: '用户协议'
    }
  },
  {
    path: '/account-pending',
    name: 'accountPending',
    component: () => import('@/views/acccount-pending/Index.vue'),
    meta: {
      title: '账号待审核'
    }
  },
  {
    path: '/joincompony',
    name: 'joincompony',
    component: () => import('@/views/bind-phone/JoinCompany.vue'),
    meta: {
      title: '申请加入企业'
    }
  },
]

const {
  VUE_APP_DEPLOY_PREFIX,
  VUE_APP_PAGE_TITLE
} = process.env

const router = createRouter({
  history: createWebHistory(VUE_APP_DEPLOY_PREFIX),
  routes
})

let startTime = Date.now()
let currentTime

router.beforeEach((to, from, next) => {

  if (to) {
    currentTime = Date.now()
    //console.log('用户由 ', from.path, ' 页面 跳转到 ', to.path, ' 页面，在 ', from.path, ' 页面停留了 ', currentTime - startTime, '毫秒。转换成秒数约为：', parseInt(String((currentTime -startTime) / 1000)))
    const logParams = {
      "hisUrl": from.path,
      "newUrl": to.path,
      "stayTime": parseInt(String((currentTime -startTime) / 1000))
    }
    logapi.post('/userbehaviorlog/saveUserLog', logParams)
    startTime = Date.now()
  }
  

  const { title, showBottom = true } = to.meta
  window.document.title = `${title} | ${VUE_APP_PAGE_TITLE}`
  layoutState.title = String(title).toString()
  layoutState.showBottom = Boolean(showBottom).valueOf()
  const loginRes = login(router, to)
  if(loginRes){
    next()
  }
  return loginRes
})

router.afterEach((to) => {
  window.document.body.scrollTop = 0
  window.document.documentElement.scrollTop = 0
  const { showNav = true } = to.meta
  layoutState.showNav = Boolean(showNav).valueOf()
    
  
  // 判断是否是首页
  // if (to.path !== '/') {
  //   layoutState.showNav = true
  // }
  setTimeout(() => {
    layoutState.isGoback = false
  }, 300)
})

export default router
