
/**
* 课程系列标签
*/
interface Tag {
  id: number;
  tagName: string;
  tagType: string;
  sort: number;
}

const createTag = ({
  id = 0,
  tagName = '',
  tagType = '0',
  sort = 1
}: {
  id?: number;
  tagName?: string;
  tagType?: string;
  sort?: number;
} = {
  id: 0,
  tagName: '',
  tagType: '',
  sort: 0
}): Tag => ({
  id,
  tagName,
  tagType,
  sort
})

export {
  createTag,
  Tag
}
