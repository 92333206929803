import { ref } from 'vue'
import { Class } from 'momai'
import { all } from '@/api/entity/class'

const classes = ref<Class[]>([])

const fetchAllClasses = () => {
  all().then(r => {
    classes.value = r
  })
}

export {
  classes,
  fetchAllClasses
}
