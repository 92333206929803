import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'admin/user-list/students',
    component: () => import('@/views/admin/user-list/UserList.vue'),
    meta: {
      title: '学员列表',
      showBottom: false
    },
    props: {
      delFlag: 1,
      toFormatter: id => `/admin/user-list/detail/${id}`
    }
  },
  {
    path: 'admin/user-list/detail/:userId',
    component: () => import('@/views/admin/user-list/detail/Index.vue'),
    meta: {
      title: '人员信息',
      showBottom: false
    }
  }
]

export default routes
