import { CourseSeries, Course } from './course'
import { User } from './user'

/**
 * 产品
 */
interface Product {
  id: number | '';
  courseId: string | '';
  type: number;
  title: string;
  img: string;
  description: string;
  descImg?: string;
  fee: number;
  score: number;
  createTime: string;
  expireTime: string;
  bonus: number | string;
  memberLimit: number;
}

/**
 * 产品视图
 */
interface ProductVo extends Product {
  course: CourseSeries[];
  courseInfos: Course[];
  members: User[];
  hasAttend: boolean;
}

interface OrderInfo {
  id: number | '';
  userId: number | '';
  fee: number;
  payFee: number;
  feeType: number;
  productId: number | string;
  status?: number;
  createTime?: string;
  payTime?: string;
  payFeeStr?: string;
  openid?: string | null;
  referrer?: any;
  type: 0 | 1;
  checked: 0 | 1;
  detail: string;
}

const buildProduct = (): Product => ({
  id: '',
  courseId: '',
  type: 0,
  title: '',
  description: '',
  fee: 0,
  score: 0,
  img: '',
  descImg: '',
  createTime: '',
  expireTime: '',
  bonus: '',
  memberLimit: 1
})

const buildProductVo = (): ProductVo => ({
  ...buildProduct(),
  course: [],
  courseInfos: [],
  members: [],
  hasAttend: false
})
const buildOrderInfo = (): OrderInfo => ({
  id: '',
  userId: '',
  detail: '',
  fee: 0,
  checked: 0,
  payFee: 0,
  feeType: 0,
  productId: '',
  payFeeStr: '0',
  type: 0
})

interface PayData {
  appId: string;
  timeStamp: string;
  nonceStr: string;
  package: string;
  signType: string;
  paySign: string;
  flag: number;
}

const buildPayData = (): PayData => ({
  appId: '',
  timeStamp: '',
  nonceStr: '',
  package: '',
  signType: '',
  paySign: '',
  flag: 1
})
export {
  Product,
  ProductVo,
  buildProduct,
  buildProductVo,
  OrderInfo,
  buildOrderInfo,
  buildPayData,
  PayData
}
