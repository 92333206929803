import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vant, { Lazyload } from 'vant'
import 'plyr/dist/plyr.css'
import 'vant/lib/index.less'
import '@/styles/utils.less'
import '@/styles/override-vant.less'
import '@/styles/index.less'
import registreGlobalComponents from '@/utils/registreGlobalComponents'
import registerGLobalDirectives from '@/utils/registerGLobalDirectives'
import '@/assets/index'

const app = createApp(App)
app
  .use(router)
  .use(Vant)
  .use(Lazyload)
  .use(registreGlobalComponents)
  .use(registerGLobalDirectives)
  .mount('#app')
//
