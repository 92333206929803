import { ref } from 'vue'
import { getUserId } from '@/utils/auth'
import { User, createUser } from 'momai'
import { detail as fetchUserInfo } from '@/api/user'
import { applicantsNum } from '@/api/user/admin'
import { api } from '@/api/useAxios'
import router from '@/router'

const user = ref<User>(createUser())
const applicants = ref(0)
const fetchApplicants = () => {
  applicantsNum().then(n => {
    applicants.value = n
  })
}
const fetchLoginUserInfo = () => {
  fetchUserInfo(getUserId()).then(({ delFlag, ...others }) => {
    user.value = {
      ...others,
      delFlag
    }
  })
}

interface ComP {
  id: number,
  companyName: string
}

const companylist = ref<ComP[]>([])

const changeCompany = (companyId: any,sucessCallBack = () => {}) => {
  const { VUE_APP_COMPANY_ID, VUE_APP_COMPANY_NAME } = process.env
  const companyName = ref('')
  api.get('/companyinfo/qryH5UserCompany').then((r) => {
    const dayObject: any = {}
      dayObject['id'] = 999
      dayObject['companyName'] = '魔脉，企业传帮带数字管家'
      companylist.value.push(dayObject)
    if(r){
      companylist.value.push(...r)
    }
    const compinfo = companylist.value.find(item => item.id === Number(companyId))
    if(compinfo){
      companyName.value = Object(compinfo).companyName
      window.sessionStorage.setItem(VUE_APP_COMPANY_ID, companyId)
      window.sessionStorage.setItem(VUE_APP_COMPANY_NAME, companyName.value)
      sucessCallBack()
      router.push('/')
    }else{
      sucessCallBack()
      router.push('/')
    }
    

  })
  
}

export {
  user,
  fetchLoginUserInfo,
  fetchApplicants,
  applicants,
  changeCompany
}
