import { api } from '@/api/useAxios'
import { AxiosRequestConfig } from 'axios'

/**
   * 返回码
   */
type ReturnCode = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

/**
 * 接口返回对象
 */
interface ApiResponse {
  code: ReturnCode;
  message: string;
  data: any;
}

/**
 * 分页数据结构
 */
interface Page {
  size: number;
  current: number;
}

interface PageResponse<T> {
  records: T[];
  total: number;
}

type ModuleApi<T> = (p: {
  subUrl: string;
  method?: 'get' | 'post';
  params?: object;
  options?: AxiosRequestConfig;
  useLoading?: boolean;
}) => Promise<T>

const createModuleApi = <T = any>(path: string): ModuleApi<T> => ({
  subUrl,
  params,
  method = 'get',
  useLoading = true,
  options = {}
}) => api[method](`/${path}/${subUrl}`, params, useLoading, options)

export {
  Page,
  ModuleApi,
  PageResponse,
  ReturnCode,
  ApiResponse,
  createModuleApi
}
