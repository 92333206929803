export default [
  {
    path: 'daily',
    component: () => import('@/views/daily/Index.vue'),
    meta: {
      title: '每日打卡',
      showNav: false
    }
  },
  {
    path: 'daily/share/:dailyWorkId',
    name: 'Share',
    component: () => import('@/views/daily/components/Share.vue'),
    meta: {
      title: '打卡详情',
      showBottom: false
    }
  }
]
