import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'admin/daily/edit',
    component: () => import('@/views/admin/daily/Index.vue'),
    meta: {
      title: '打卡设置',
      showBottom: false
    }
  },
  {
    path: 'admin/daily/audit',
    component: () => import('@/views/admin/daily/Audit.vue'),
    meta: {
      title: '打卡内容审核',
      showBottom: false
    }
  }
]

export default routes
