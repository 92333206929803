export default [
  {
    path: 'study',
    component: () => import('@/views/study/Index.vue'),
    meta: {
      title: '学习圈',
      showNav: false
    }
  }
]
