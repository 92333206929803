import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'admin/user-list/admin',
    component: () => import('@/views/admin/user-list/UserList.vue'),
    meta: {
      title: '管理员列表',
      showBottom: false
    },
    props: {
      delFalg: null,
      toFormatter: id => `/admin/user-list/detail/${id}`
    }
  }
]

export default routes
