export default [
  {
    path: 'sales/product/:productId',
    name: 'salesProduct',
    component: () => import('@/views/sales/product/Index.vue'),
    meta: {
      title: '活动详情',
      showBottom: false,
      showNav: false
    }
  },
  {
    path: 'sales/product/list',
    name: 'productList',
    component: () => import('@/views/sales/product/List.vue'),
    meta: {
      title: '活动列表',
      showBottom: false,
      showNav: false
    }
  }
]
