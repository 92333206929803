import axios from 'axios'
import { Notify, Toast, Dialog } from 'vant'
import { getToken } from '@/utils/auth'
import { pagelogin } from '@/utils/wechat'
import { useRouter } from 'vue-router'

let loading = false

let requestInterval = null // 请求时间检查器

let requestTime = 0 // 请求时间计数器

// 创建计时器
const createCounter = () => {
  if (!requestInterval) {
    requestInterval = setInterval(() => {
      if (requestTime > 100 && !loading) {
        Toast.loading({
          duration: 0,
          forbidClick: true,
          text: '加载中...'
        })
        loading = true
      } else {
        requestTime += 10
      }
    }, 10)
  }
}

// 重置计时器
const resetCounter = () => {
  if (requestInterval) {
    clearInterval(requestInterval)
    requestInterval = null
  }
  Toast.clear()
  loading = false
  requestTime = 0
}

const timeout = 80000
const createApi = (apiInstance, needHandleRes = true) => {

  const router = useRouter()


  const processRes = (res, resolve, reject) => {
    const { data } = res
    if (!needHandleRes) {
      resolve(data)
      return
    }
    resolve(data.data)
  }

  apiInstance.interceptors.request.use(
    req => {
      const { useLoading } = req
      if (useLoading) {
        createCounter()
      }
      delete req.headers.useLoading
      const t = getToken()
      if (t) {
        req.headers.Authorization = `Bearer ${t}`
      }
      return req
    }
  )

  apiInstance.interceptors.response.use(res => {
    resetCounter()
    return res
  })
  const postOrPut = (method = 'post') => (url, payload = {}, useLoading = false, otherOpts = {}) =>
    new Promise((resolve, reject) => {
      apiInstance[method](url, payload, {
        useLoading,
        ...otherOpts
      })
        .then(res => processRes(res, resolve, reject))
    })
  return {
    post: postOrPut(),
    put: postOrPut('put'),
    delete: postOrPut('delete'),
    get: (url, queryString, useLoading = false, otherOpts = {}) =>
      new Promise((resolve, reject) => {
        apiInstance
          .get(
            url,
            {
              params: queryString
            },
            {
              useLoading,
              ...otherOpts
            }
          )
          .then(res => {
            processRes(res, resolve, reject)
          })
      })
  }
}

const logapi = createApi(
  axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout
  })
)

export { logapi }
