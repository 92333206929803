import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'admin/tag/add',
    component: () => import('@/views/admin/tag-list/add/Index.vue'),
    meta: {
      title: '添加标签',
      showBottom: false
    }
  },
  {
    path: 'admin/tag/edit/:tagId',
    component: () => import('@/views/admin/tag-list/edit/Index.vue'),
    meta: {
      title: '编辑标签',
      showBottom: false
    }
  }
]

export default routes
