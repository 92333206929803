import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'admin/sales/product-list',
    component: () => import('@/views/admin/sales/list/Index.vue'),
    meta: {
      title: '活动产品列表',
      showBottom: false
    }
  }, {
    path: 'admin/sales/detail/:productId',
    component: () => import('@/views/admin/sales/add/Index.vue'),
    meta: {
      title: '活动产品配置',
      showBottom: false
    }
  }, {
    path: 'admin/sales/add',
    component: () => import('@/views/admin/sales/add/Index.vue'),
    meta: {
      title: '活动产品配置',
      showBottom: false
    }
  }
]

export default routes
