import { ref } from 'vue'
import { Class } from 'momai'
import { api } from '@/api/useAxios'

const checkedClasses = ref<Class[]>([])

const fetchCheckedClasses = () => {
  api.get('/classinfo/checkedClasses').then(r => {
    checkedClasses.value = r
  })
}

export {
  checkedClasses,
  fetchCheckedClasses
}
