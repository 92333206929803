import router from '@/router'
import fakeAuth from './fakeAuth'

const {
  VUE_APP_TOKEN_KEY,
  VUE_APP_USER_ID_KEY,
  VUE_APP_USER_DEL_FLAG_KEY,
  VUE_APP_OPENID_KEY,
  VUE_APP_USE_FAKE_AUTH,
  VUE_APP_COMPANY_ID
} = process.env

const useFakeAuth = VUE_APP_USE_FAKE_AUTH === 'true'

// 获取token
const getToken = () => useFakeAuth ? fakeAuth.token : window.sessionStorage.getItem(VUE_APP_TOKEN_KEY)

// 获取用户ID
const getUserId = () => useFakeAuth ? fakeAuth.userId : window.sessionStorage.getItem(VUE_APP_USER_ID_KEY)

// 获取用户ID
const getOpenid = () => useFakeAuth ? fakeAuth.openid : window.sessionStorage.getItem(VUE_APP_OPENID_KEY)

// 获取用户DelFlag
const getDelFlag = () => useFakeAuth ? fakeAuth.delFlag : window.sessionStorage.getItem(VUE_APP_USER_DEL_FLAG_KEY)

// 获取当前选择的企业id
const getCompanyId = () => window.sessionStorage.getItem(VUE_APP_COMPANY_ID) ? window.sessionStorage.getItem(VUE_APP_COMPANY_ID) : 999


// 设置token
const setToken = ({
  token,
  id,
  delFlag,
  openid
}: {
  token: string;
  delFlag: string;
  id: string;
  openid: string;
}) => {
  window.sessionStorage.setItem(VUE_APP_TOKEN_KEY, token)
  window.sessionStorage.setItem(VUE_APP_USER_ID_KEY, id)
  window.sessionStorage.setItem(VUE_APP_USER_DEL_FLAG_KEY, delFlag)
  window.sessionStorage.setItem(VUE_APP_OPENID_KEY, openid)
}

// 移除token
const logout = () => {
  window.sessionStorage.removeItem(VUE_APP_TOKEN_KEY)
  window.sessionStorage.removeItem(VUE_APP_USER_ID_KEY)
  window.sessionStorage.removeItem(VUE_APP_USER_DEL_FLAG_KEY)
  router.push('/login')
}

export {
  getToken,
  getDelFlag,
  setToken,
  getUserId,
  getOpenid,
  logout,
  getCompanyId
}
