import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'homework/upload/:courseId',
    component: () => import('@/views/homework/upload/Index.vue'),
    meta: {
      title: '提交作业',
      showBottom: false
    }
  },
  {
    path: 'homework/detail/:homeworkId',
    name: 'homeworkDetail',
    component: () => import('@/views/homework/detail/Index.vue'),
    meta: {
      title: '作业详情',
      showBottom: false
    }
  }
]

export default routes
